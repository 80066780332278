/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import styles from './NotificationPopup.module.scss';
import useNotifications from './useNotifications';
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import { ERROR_NOTIFICATION, SUCCESS_NOTIFICATION } from '../../state/notifications/notifications';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
//todo: handle multiple notification types
const Notification = ({ text, status, removeNotification }) => {
  const titles = {
    [ERROR_NOTIFICATION]: 'error',
    [SUCCESS_NOTIFICATION]: 'success',
    warning: 'warning',
    pending: 'pending',
  };
  return (
    <div
      className={classNames(styles.notification, {
        [styles.error]: status === ERROR_NOTIFICATION,
        [styles.success]: status === SUCCESS_NOTIFICATION,
      })}
    >
      <section className={styles.headline}>
        <header>
          <FormattedMessage id={`notifications.${titles[status]}`} defaultMessage="Notification" />
        </header>
        <div className={styles.closeBtn} onClick={removeNotification}>
          <CloseIcon />
        </div>
      </section>
      <p className={styles.message}>{text}</p>
    </div>
  );
};

const NotificationPopup = () => {
  const { notifications, removeNotification } = useNotifications();

  useEffect(() => {
    let timeout = null;

    if (notifications?.length) {
      timeout = setTimeout(removeNotification, 5000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [notifications?.length, removeNotification]);

  if (!notifications?.length) return null;

  return (
    <div className={styles.root}>
      {notifications.reverse().map((notification) => (
        <Notification
          key={notification.text.slice(-20)}
          removeNotification={removeNotification}
          text={notification.text}
          status={notification.status}
        />
      ))}
    </div>
  );
};

export default NotificationPopup;
